<template>
  <section class="bg-white p-2 rounded mb-4">
    <b-row>
      <b-col md="4">
        <questionnaire-menu />
      </b-col>
      
      <b-col md="8" class="pt-2 pt-md-0">
        <div class="h-100">
          <keep-alive>
            <component v-bind:is="component" />
          </keep-alive>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import store from "@/store/index";
  import { BRow, BCol, BFormSelect, BButton } from "bootstrap-vue";
  import QuestionnaireMenu from '../../components/questionnaire/QuestionnaireMenu.vue';
  import NewQuestion from '../../components/questionnaire/NewQuestion.vue';
  import SetupQuestionnaireDetails from '../../components/questionnaire/SetupQuestionnaireDetails.vue';
  import PreviewQuestionnaire from '../../components/questionnaire/PreviewQuestionnaire.vue';

  export default {
    components: {
      BRow,
      BCol,
      BFormSelect,
      BButton,

      QuestionnaireMenu,
      NewQuestion,
      SetupQuestionnaireDetails,
      PreviewQuestionnaire
    },

    computed: {
      component() {
        return store.state.questionnaireStoreModule.questionnaireComponent;
      }
    },

    data() {
      return {
      }
    },

    mounted() {
      
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
